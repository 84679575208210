<template>
  <div class="page-box" id='group-id'>
    <div class="btn-box" style='margin-bottom:12px'>
      <a-button type="primary" @click="handleShowAdd" v-pms="'add'">
        添加分组
      </a-button>
    </div>
    <base-table bordered :columns="columns" :dataSource="dataRef" :pagination="pageRtv" :rowKey="record => record.productGroupId" :loading="loadingRef" @change="handleTableChange">
      <template #operation="record">
        <div class="table-operations">
          <a-button type="link" v-if='record.seqNum !== -1' @click="handleViewDetail(record)">
            查看
          </a-button>
          <a-button type="link" v-pms="'edit'" @click="handleShowEdit(record)">
            编辑
          </a-button>
          <a-popconfirm v-if="record.seqNum !== -1 && record.productNum === 0" v-pms="'delete'" placement="topRight" title="确定删除该商品分组吗？" ok-text="确定" cancel-text="取消" @confirm="handleDelete(record.productGroupId)">
            <a-button type="link">删除</a-button>
          </a-popconfirm>
        </div>
      </template>
    </base-table>
    <modal-tags-edit :idName='"group-id"' :show.sync="tagsEditShow" :init-data="currentTag" @update="handleSearch" />
    <a-modal :footer='null' :getContainer="getContainer" width='900px' v-model="showDetail" :title='currentRecord.groupName'>
      <a-spin :spinning='detailLoading'>
        <a-table bordered :columns="productColumns" :dataSource="goodsList" :pagination="false" :rowKey="record => record.productId">
          <template slot="mainPicPath" slot-scope="text, record">
            <base-img class="goods-img" :src="record.mainPicPath" width="60" height="60" oss-style="60_60" />
          </template>
          <template slot="operation" slot-scope="text, record">
            <div class="table-operations">
              <a-button type="link" @click='$router.push("/goods/publish/edit/"+record.productId)'>
                编辑
              </a-button>
            </div>
          </template>
        </a-table>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { useTableList } from '@/hooks'
import { shop } from '@/api'
import { SHOPID } from '@/constants'
import { getSession } from '@/utils/session'
import ModalTagsEdit from './ModalTagsEdit'
const columns = [
  {
    title: '分组名称',
    dataIndex: 'groupName'
  },
  {
    title: '商品数',
    dataIndex: 'productNum'
  },
  {
    title: '序号',
    dataIndex: 'seqNum'
  },
  {
    title: '操作',
    scopedSlots: {
      customRender: 'operation'
    }
  }
]
const productColumns = [
  {
    title: '序号',
    dataIndex: 'seqNum',
    customRender: (x) => {
      return x === undefined ? '未排序' : x
    }
  },
  {
    title: '主图',
    dataIndex: 'mainPicPath',
    scopedSlots: { customRender: 'mainPicPath' }
  },
  {
    title: '商品名称',
    dataIndex: 'name'
  },
  {
    title: '划线价',
    dataIndex: 'originalPrice'
  },
  {
    title: '现价',
    dataIndex: 'price'
  },
  {
    title: '库存',
    dataIndex: 'stock'
  },
  {
    title: '销量',
    dataIndex: 'sales'
  },
  {
    title: '虚拟销量',
    dataIndex: 'virtualSale'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' }
  }
]

export default {
  name: 'PageTags',

  components: {
    ModalTagsEdit
  },

  setup (props, { root }) {
    const { dataRef, pageRtv, loadingRef, resetPage, setPage, refresh } = useTableList(
      shop.getProductGroupPage,
      {},
      true,
      (res) => res
    )

    function handleSearch () {
      resetPage()
      refresh()
    }
    function handleTableChange ({ current }) {
      setPage(current)
    }
    const detailLoading = ref(false)
    const currentTag = ref('')
    const tagsEditShow = ref(false)
    const showDetail = ref(false)
    const currentRecord = ref({})
    const goodsList = ref([])
    function handleShowAdd () {
      currentTag.value = ''
      tagsEditShow.value = true
    }
    function handleShowEdit (record) {
      currentTag.value = record
      tagsEditShow.value = true
    }
    async function handleDelete (id) {
      loadingRef.value = true
      const { code, msg } = await shop.deleteProductGroup(id)
      loadingRef.value = false
      if (code !== '00000') return root.$message.error(msg || '删除失败，请稍后重试')
      root.$message.success('删除成功')
      resetPage()
      refresh()
    }
    function getContainer () {
      return document.getElementById('group-id')
    }

    async function handleViewDetail (record) {
      currentRecord.value = record
      showDetail.value = true
      detailLoading.value = true
      const { data, msg, code } = await shop.getProductByGroup({
        shopId: getSession(SHOPID),
        productGroupId: record.productGroupId,
        type: 1
      })
      detailLoading.value = false
      if (code === '00000') {
        goodsList.value = data
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }

    return {
      productColumns,
      goodsList,
      detailLoading,
      currentRecord,
      columns,
      showDetail,
      dataRef,
      pageRtv,
      loadingRef,
      handleSearch,
      handleTableChange,
      currentTag,
      tagsEditShow,
      handleShowAdd,
      handleShowEdit,
      handleDelete,
      handleViewDetail,
      getContainer
    }
  }
}
</script>
